<template>
  <v-container fluid class="pt-0 align-start fill-height">
    <MainModal
        :main="{ component: 'AmalgamatedHromada', title: 'Об’єднані громади' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_amalgamated_hromada)"
        :modal="show_amalgamated_hromada_dialog"
        @updateItemModal="amalgamatedHromadaUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'City', title: 'Населені пункти' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_city)"
        :modal="show_city_dialog"
        @updateItemModal="cityUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'CityArea', title: 'Райони міста' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_city_area)"
        :modal="show_city_area_dialog"
        @updateItemModal="cityAreaUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'Street', title: 'Вулиці' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_street)"
        :modal="show_street_dialog"
        @updateItemModal="streetUpdateItemModal"
    />
    <v-bottom-sheet
        right
        app
        disable-route-watcher
        bottom
        max-width="1050"
        v-model="dictionary_katottg_dialog">
      <DictionaryKatottg @afterCityCreate="afterCityCreate"/>
    </v-bottom-sheet>
    <v-row style="height: 100%">
      <v-col cols="12" md="12">
        <v-card-text class="pa-0" style="height: 100%">
          <v-tabs class="custom-tabs" color="success" v-model="tab" style="height: 100%">
            <v-tab class="text-left justify-start">
              Об’єднані громади
            </v-tab>

            <v-tab class="text-left justify-start">
              Населені пункти
            </v-tab>

            <v-tab class="text-left justify-start">
              Райони міста
            </v-tab>

            <v-tab class="text-left justify-start">
              Вулиці
            </v-tab>

            <v-tab class="text-left justify-start">
              Будинки
            </v-tab>

            <v-tab-item class="pa-0" style="height: 100%">
              <v-row style="height: 100%">
                <v-col cols="12" md="12">
                  <v-card tile elevation="1">
                    <v-toolbar elevation="0" class="pa-0">
                      <v-toolbar-title>
                        Об’єднані громади
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="dictionary_katottg_dialog = true"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-2">
                            <v-icon color="secondary">mdi-bookshelf</v-icon>
                          </v-btn>
                        </template>
                        <span>Довідник КАТОТТГ</span>
                      </v-tooltip>
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openAmalgamatedHromadaCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити нову об’єднану громаду</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="pt-0">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select :items="regionSelect"
                                    filled hide-details
                                    label="Область" v-model="filters.amalgamated.region"
                                    color="grey"
                                    clearable
                                    @change="getAmalgamatedHromdas"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="filters.amalgamated.search_text" filled
                                        hide-details color="grey" label="Пошук громади" clearable
                                        @input="getAmalgamatedHromdas"
                          />
                        </v-col>
                      </v-row>

                    </v-card-text>
                    <v-data-table
                        :headers="amalgamatedHromadaHeaders"
                        :items="hromadas"
                        no-data-text="Не створено жодної об’єднаної громади. Натистніть + для створення"
                        :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
                        class="custom-table custom-table-1"
                        @click:row="onAmalgamatedHromadaItemClick"
                    >
                      <template v-slot:item.icon>
                        <v-icon size="26" color="primary lighten-1">mdi-city-variant-outline</v-icon>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:item.create_date="{ item }">
                        <span>
                          {{ item.create_date | formatDate }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0" style="height: 100%">
              <v-row style="height: 100%">
                <v-col cols="12" md="12">
                  <v-card tile elevation="1">
                    <v-toolbar elevation="0" class="pa-0">
                      <v-toolbar-title>
                        Населені пункти
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="dictionary_katottg_dialog = true"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-2">
                            <v-icon color="secondary">mdi-bookshelf</v-icon>
                          </v-btn>
                        </template>
                        <span>Довідник КАТОТТГ</span>
                      </v-tooltip>
                      <YouTubeButton
                          button-class="grey lighten-4 mr-2"
                          icon-color="error lighten-1"
                          :icon-size="26"
                          href="https://youtu.be/LFousBkvF38"
                      />
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openCityCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити новий населений пункт</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="pt-0">
                      <v-row>
                        <v-col cols="12" md="6">
                          <AddressElementSelect v-model="filters.city.amalgamated_id"
                                                label="Об’єднана громада" filled
                                                select_type="amalgamated_hromada"
                                                @selectChanged="getCities"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="filters.city.search_text" filled
                                        hide-details color="grey" label="Пошук населеного пункту" clearable
                                        @input="getCities"
                          />
                        </v-col>
                      </v-row>

                    </v-card-text>
                    <v-data-table
                        :headers="cityHeaders"
                        :items="cities"
                        no-data-text="Не створено жодного населеного пункту. Натистніть + для створення"
                        :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
                        class="custom-table custom-table-1"
                        @click:row="onCityItemClick"
                    >
                      <template v-slot:item.icon>
                        <v-icon size="26" color="primary lighten-1">mdi-city-variant-outline</v-icon>
                      </template>
                      <template v-slot:item.type="{ item }">
                        <v-chip label outlined small :color="getCityIcon(item.city_type, 'color')" dark class="my-1">
                          {{ cityTypes[item.city_type] }}
                        </v-chip>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:item.create_date="{ item }">
                        <span>
                          {{ item.create_date | formatDate }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0" style="height: 100%">
              <v-row style="height: 100%">
                <v-col cols="12" md="12">
                  <v-card tile elevation="1">
                    <v-toolbar elevation="0" class="pa-0">
                      <v-toolbar-title>
                        Райони міста
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="dictionary_katottg_dialog = true"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-2">
                            <v-icon color="secondary">mdi-bookshelf</v-icon>
                          </v-btn>
                        </template>
                        <span>Довідник КАТОТТГ</span>
                      </v-tooltip>
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openCityAreaCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити новий район населеного пункту</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="pt-0">
                      <v-row>
                        <v-col cols="12" md="6">
                          <AddressElementSelect v-model="filters.city_area.city_id"
                                                label="Населений пункт" filled
                                                select_type="city"
                                                @selectChanged="getCityAreas"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="filters.city_area.search_text" filled
                                        hide-details color="grey" label="Пошук району міста" clearable
                                        @input="getCityAreas"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-data-table
                        :headers="cityAreaHeaders"
                        :items="city_areas"
                        no-data-text="Не створено жодного району міста. Натисніть + для створення"
                        :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
                        class="custom-table custom-table-1"
                        @click:row="onCityAreaItemClick"
                    >
                      <template v-slot:item.icon>
                        <v-icon size="26" color="primary lighten-1">mdi-city</v-icon>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:item.create_date="{ item }">
                        <span>
                          {{ item.create_date | formatDate }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0" style="height: 100%">
              <v-row style="height: 100%">
                <v-col cols="12" md="12">
                  <v-card tile elevation="1">
                    <v-toolbar elevation="0" class="pa-0">
                      <v-toolbar-title>
                        Вулиці
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <YouTubeButton
                          button-class="grey lighten-4 mr-2"
                          icon-color="error lighten-1"
                          :icon-size="26"
                          href="https://youtu.be/LFousBkvF38"
                      />
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openStreetCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити нову вулицю</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="pt-0">
                      <v-row>
                        <v-col cols="12" md="4">
                          <AddressElementSelect v-model="filters.street.city_id"
                                                label="Населений пункт" filled
                                                select_type="city"
                                                @selectChanged="getStreets"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <AddressElementSelect v-model="filters.street.city_area_id"
                                                label="Район міста" filled
                                                select_type="city-area"
                                                :parent_id="filters.street.city_id" :use_parent_id="true"
                                                @selectChanged="getStreets"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field v-model="filters.street.search_text" filled
                                        hide-details color="grey" label="Пошук вулиці" clearable
                                        @input="onStreetsSearch"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-data-table
                        :headers="streetHeader"
                        :items="streets"
                        no-data-text="Не створено жодної вулиці. Натистніть + для створення"
                        :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
                        class="custom-table custom-table-1"
                        @click:row="onStreetItemClick"
                    >
                      <template v-slot:item.icon>
                        <v-icon size="26" color="primary lighten-1">mdi-city</v-icon>
                      </template>
                      <template v-slot:item.type="{ item }">
                        <v-chip label outlined small :color="getStreetIcon(item.street_type, 'color')" dark
                                class="my-1">
                          {{ streetTypes[item.street_type] }}
                        </v-chip>
                      </template>
                      <template v-slot:item.name="{ item }">
                        <span class="font-weight-medium">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:item.create_date="{ item }">
                        <span>
                          {{ item.create_date | formatDate }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0" style="height: 100%">
              <Buildings/>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  cityTypes,
  streetTypes,
  getCityIcon,
  getStreetIcon,
  regionSelect,
  debounce
} from "@/utils/icons";
import {mapGetters, mapActions} from 'vuex'
import {FETCH_CITIES, FETCH_STREETS} from "@/store/actions/city";
import {FETCH_AMALGAMATED_HROMADA} from "@/store/actions/amalgamated_hromada";
import {FETCH_CITY_AREA} from "@/store/actions/city_area";

export default {
  name: "StreetView",
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    Buildings: () => import("@/components/Buildings"),
    YouTubeButton: () => import("@/components/YouTubeButton"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    DictionaryKatottg: () => import('@/components/for_fill/DictionaryKatottg.vue')
  },
  data() {
    return {
      dictionary_katottg_dialog: false,
      regionSelect,
      tab: null,
      amalgamated_hromada_id: null,
      city_id: null,
      cityTypes,
      streetTypes,
      street_dialog_id: 0,
      amalgamated_hromada_dialog_id: 0,
      amalgamatedHromadaHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Код', value: 'code'},
        {text: 'Область', value: 'region'},
        {text: 'Район', value: 'district'},
        {text: 'Назва', value: 'name'},
        {text: 'Код КАТОТТГ', value: 'code_katottg'},
        {text: 'Дата створення', value: 'create_date'},
      ],
      cityHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Код', value: 'code'},
        {text: 'Область', value: 'region'},
        {text: 'Район', value: 'district'},
        {text: 'Громада', value: 'amalgamated_hromada.name'},
        {text: 'Тип', value: 'type'},
        {text: 'Назва', value: 'name'},
        {text: 'Дата створення', value: 'create_date'},
        {text: 'Автор', value: 'owner_name'},
      ],
      cityAreaHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Нас. пункт', value: 'city.name'},
        {text: 'Код', value: 'code'},
        {text: 'Мікрорайон', value: 'sub_name'},
        {text: 'Назва', value: 'name'},
        {text: 'Код КАТОТТГ', value: 'code_katottg'},
        {text: 'Дата створення', value: 'create_date'}
      ],
      streetHeader: [
        {text: '', value: 'icon', width: 26},
        {text: 'Нас. пункт', value: 'city.name'},
        {text: 'Район нас. пункт', value: 'city_area.name'},
        {text: 'Код', value: 'code'},
        {text: 'Тип', value: 'type'},
        {text: 'Назва', value: 'name'},
        {text: 'К-сть буд.', value: 'count_buildings'},
        {text: 'К-сть факт', value: 'count_buildings_fact'},
        {text: 'К-сть баг.', value: 'count_buildings_high_rise'},
        {text: 'Дата створення', value: 'create_date'},
        {text: 'Автор', value: 'owner_name'},
      ],
      filters: {
        amalgamated: {
          region: null,
          search_text: null
        },
        city: {
          amalgamated_id: null,
          search_text: null
        },
        city_area: {
          city_id: null,
          search_text: null
        },
        street: {
          city_id: null,
          city_area_id: null,
          search_text: null
        }
      },
      debounceStreet: null,

      selected_amalgamated_hromada: {},
      show_amalgamated_hromada_dialog: false,

      selected_city: {},
      show_city_dialog: false,

      selected_city_area: {},
      show_city_area_dialog: false,

      selected_street: {},
      show_street_dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'getCities',
      city_areas: 'getCityAreas',
      hromadas: 'getAmalgamatedHromadas',
      streets: 'getStreets'
    })
  },
  methods: {
    getCityIcon,
    getStreetIcon,
    ...mapActions({
      fetchCities: FETCH_CITIES,
      fetchStreets: FETCH_STREETS,
      fetchHromadas: FETCH_AMALGAMATED_HROMADA,
      fetchCityAreas: FETCH_CITY_AREA
    }),
    getAmalgamatedHromdas() {
      this.$nextTick(() => {
        const payload = {
          search_text: this.filters.amalgamated.search_text,
          region: this.filters.amalgamated.region
        }
        this.fetchHromadas(payload)
      })
    },
    getCities() {
      this.$nextTick(() => {
        const payload = {
          search_text: this.filters.city.search_text,
          amalgamated_id: this.filters.city.amalgamated_id
        }
        this.fetchCities(payload)
      })
    },
    getCityAreas() {
      this.$nextTick(() => {
        const payload = {
          search_text: this.filters.city_area.search_text,
          city_id: this.filters.city_area.city_id
        }
        this.fetchCityAreas(payload)
      })
    },
    getStreets() {
      this.$nextTick(() => {
        const payload = {
          search_text: this.filters.street.search_text,
          city_id: this.filters.street.city_id,
          city_area_id: this.filters.street.city_area_id
        }
        this.fetchStreets(payload)
      })
    },
    onStreetsSearch() {
      this.debounceStreet()
    },

    amalgamatedHromadaUpdateItemModal() {
      this.show_amalgamated_hromada_dialog = false
      this.selected_amalgamated_hromada = {}
    },
    openAmalgamatedHromadaCreateDialog() {
      this.selected_amalgamated_hromada = {}
      this.show_amalgamated_hromada_dialog = true
    },
    onAmalgamatedHromadaItemClick(payload) {
      this.selected_amalgamated_hromada = JSON.parse(JSON.stringify(payload))
      this.show_amalgamated_hromada_dialog = true
    },

    cityUpdateItemModal() {
      this.show_city_dialog = false
      this.selected_city = {}
    },
    openCityCreateDialog() {
      this.selected_city = {}
      this.show_city_dialog = true
    },
    onCityItemClick(payload) {
      this.selected_city = JSON.parse(JSON.stringify(payload))
      this.show_city_dialog = true
    },

    cityAreaUpdateItemModal() {
      this.show_city_area_dialog = false
      this.selected_city_area = {}
    },
    openCityAreaCreateDialog() {
      this.selected_city_area = {}
      this.show_city_area_dialog = true
    },
    onCityAreaItemClick(payload) {
      this.selected_city_area = JSON.parse(JSON.stringify(payload))
      this.show_city_area_dialog = true
    },

    streetUpdateItemModal() {
      this.show_street_dialog = false
      this.selected_street = {}
    },
    openStreetCreateDialog() {
      this.selected_street = {}
      this.show_street_dialog = true
    },
    onStreetItemClick(payload) {
      this.selected_street = JSON.parse(JSON.stringify(payload))
      this.show_street_dialog = true
    },

    afterCityCreate(payload) {
      if (payload.ah_create) {
        this.getAmalgamatedHromdas()
      }
      if (payload.cy_create) {
        this.getCities()
      }
      if (payload.cr_create) {
        this.getCityAreas()
      }

      this.dictionary_katottg_dialog = false
    }
  },
  created() {
    this.debounceStreet = debounce(() => this.getStreets(), 800)
    this.getAmalgamatedHromdas()
    this.getCities()
    this.getStreets()
    this.getCityAreas()

    if (this.$route.query.current) {
      this.tab = +this.$route.query.current || 0
    }
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_GEO_DATA')
  }
}
</script>

<style scoped lang="scss">
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>